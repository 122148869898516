import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Mike0Mania - Mint EP",
  "cover": "images/mikeomania-mint.jpg",
  "coverAlt": "Mike0Mania - Mint EP - Music Artwork",
  "description": "Graphic design for Mike0Mania's Mint EP - Music Artwork",
  "date": "2018-03-14",
  "dateModified": "2018-03-14",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design", "Music Artwork"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`My friend wanted an music artwork for his `}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://soundcloud.com/iammikeo/sets/mint"
          }}>{`new release titled Mint`}</a></em></strong>{`. He wanted something that was fresh like trident/Dentyne gum packaging; I suggested we do a take on the trending glitch style.`}</p>
    <h2 {...{
      "id": "skills-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills-used",
        "aria-label": "skills used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills Used`}</h2>
    <p>{`Photoshop, Illustrator, magic sauce.`}</p>
    <h2 {...{
      "id": "screenshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshot",
        "aria-label": "screenshot permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshot`}</h2>
    <p><img parentName="p" {...{
        "src": "images/Glitch-10ddbc7f-5c9d-4f2e-9c0f-5db57f02fc99.jpg",
        "alt": "Mike0Mania - Mint EP - Music Artwork"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      